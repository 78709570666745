import type { GetServerSideProps, NextPage } from 'next';
import { z } from 'zod';
import Script from 'next/script';
import { getCachedEntryByUrl } from '../utils/requests/contentstack/cachedRequests';
import { formatError, serverLogger } from '../utils/loggers/server';
import { modularPageWrapperSchema } from '../components/ModularPageWrapper/schema';
import { ModularPageWrapper } from '../components/ModularPageWrapper';
import { isAllSettledResponseFailure } from '../utils/promises';
import { getDynamicAdsRequest } from '../features/dynamicAds/utils/getDynamicAdsRequest';
import { getModulesWithHeroOverride } from '../features/dynamicAds/utils/getModulesWithHeroOverride';

export type GatewayPageProps = z.infer<typeof modularPageWrapperSchema>;

export const getServerSideProps: GetServerSideProps<GatewayPageProps> = async ({
  locale,
  req,
}) => {
  try {
    const dynamicAdsRequest = getDynamicAdsRequest({ req });
    const entryRequest = getCachedEntryByUrl({
      contentType: 'modular_page',
      url: '/gateway-onboarding-vision',
      language: locale,
      schema: modularPageWrapperSchema,
      logger: serverLogger,
    });

    const responses = await Promise.allSettled([
      entryRequest,
      dynamicAdsRequest,
    ]);

    const [entryResponse, dynamicAdsResponse] = responses;
    const dynamicAdsData =
      dynamicAdsResponse.status === 'fulfilled'
        ? dynamicAdsResponse.value?.data
        : undefined;

    if (isAllSettledResponseFailure(entryResponse)) {
      throw entryResponse.reason;
    }

    return {
      props: {
        seoData: entryResponse.value.data.seoData,
        modules: getModulesWithHeroOverride({
          businessLinePage: 'gateway',
          modules: entryResponse.value.data.modules,
          dynamicAds: dynamicAdsData,
        }),
      },
    };
  } catch (error: unknown) {
    serverLogger({
      level: 'error',
      team: 'client-onboarding',
      message: 'Required page data is undefined - Gateway',
      context: {
        error: formatError(error),
        page: '/',
        severity: 'critical',
      },
    });

    throw new Error('Required data not provided to render Gateway page');
  }
};

const GatewayPage: NextPage<GatewayPageProps> = ({ seoData, modules }) => {
  return (
    <>
      <Script
        // Blueshift web conversion integration (https://experiences.getblueshift.com/)
        data-testid="blueshift-script"
        src="https://www.lightboxcdn.com/vendor/3767d51a-4af9-4270-8ee4-c7310e4371b0/lightbox_speed.js"
        defer
      />
      <ModularPageWrapper seoData={seoData} modules={modules} />
    </>
  );
};

export default GatewayPage;
